/* eslint-disable no-unused-vars */
import axios from 'axios';
import {
  authLogoutAction,
  authSetTokenAction,
  authSetUserAction,
  history,
  store,
  userPrefsSetAssetFiltersAction,
} from '../redux';
import { userPrefsInitializeAction } from '../redux/reducers/userPrefsReducer';
import { Api } from '.';

class AuthService {
  // Define the current map tour version (increment this when a new version is released)
  MAP_TOUR_VERSION = 'v1'; // For new map versions, update this to 'v2', 'v3', etc.

  login = async ({ username, password }) => {
    try {
      const response = await axios.post(
        `${window._sonar_env.REACT_APP_BASE_API_URL_V2}/authentication/login`,
        {
          username,
          password,
        },
      );

      if (response.status !== 202 && response.status !== 200) {
        throw new Error('Not authenticated');
      }

      const { data } = response;

      if (data.passwordResetToken) {
        return { newUserPWToken: data.passwordResetToken, email: username };
      } else {
        axios.defaults.headers.Authorization = `Bearer ${data.accessToken}`;

        const { user } = data;
        // Checking if user has default application id or automatically selects the first application id
        const defaultAppId =
          user.defaultApplication || (user.applications && user.applications.length > 0)
            ? Number(user.applications[0])
            : null;

        store.dispatch(
          authSetUserAction({
            id: user.id,
            email: user.email,
            defaultApplication: defaultAppId,
            apps: user.applications,
            role: user.role,
          }),
        );
        store.dispatch(authSetTokenAction(data.accessToken));
        store.dispatch(userPrefsSetAssetFiltersAction(user.assetFilters));
        store.dispatch(
          userPrefsInitializeAction(data.user.temperatureUnit, data.user.assetFilters),
        );

        // TODO: keeping these localStorage calls in place until move on to new api and can dump prev Axios instance
        localStorage.setItem('email', data.user.email);
        localStorage.setItem('defaultApplication', defaultAppId);
        localStorage.setItem('applications', data.user.applications);
        localStorage.setItem('token', 'Bearer ' + data.accessToken);
        localStorage.setItem('tokenExpiration', data.tokenExpiration);
        localStorage.setItem('assetFilters', JSON.stringify(data.user.assetFilters));
        localStorage.setItem('refreshTokenExpiration', data.refreshTokenExpiration);
        localStorage.setItem('refreshToken', data.refreshToken);

        // Check and store map tour version
        const storedMapTourVersion = localStorage.getItem('mapTourVersion');
        if (storedMapTourVersion !== this.MAP_TOUR_VERSION) {
          // Store the new map tour version in localStorage if it's a new version
          localStorage.setItem('mapTourVersion', this.MAP_TOUR_VERSION);
        }

        return data;
      }
    } catch (error) {
      if (error.response) {
        // Extract the error message
        const errorMessage = error.response.data.message;
        const errSubStr = errorMessage.substring(
          errorMessage.indexOf(': ') + 1,
          errorMessage.indexOf('\n'),
        );

        let errorData = {
          errorStatus: error.response.status,
          message: errSubStr,
        };

        // Checking if error message contains user is locked out
        if (errorMessage.includes('UserLockoutException')) {
          // Formatting & getting browser's current time plus 5 minutes and time zone
          let currentDate = new Date();
          let zone = currentDate
            .toLocaleTimeString('en-us', { timeZoneName: 'short' })
            .split(' ')[2];
          let newDate = currentDate.setTime(new Date().getTime() + 5 * 60 * 1000);
          let fiveMin = new Intl.DateTimeFormat('default', {
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
          }).format(newDate);

          const lockMsg = `Account Locked. Please reset your password at ${fiveMin} ${zone}.`;
          errorData.message = lockMsg;
          // Storing lockout expiration time in localStorage
          localStorage.setItem('lockoutExpiration', newDate);
          return errorData;
        } else {
          return errorData;
        }
      }
    }
  };

  sso = async () => {
    window.open(`${window._sonar_env.REACT_APP_BASE_API_URL_V2}/authenticate?isWeb=true`, '_blank');
  };

  signup = async ({ username }) => {
    const { data } = await axios.post(
      `${window._sonar_env.REACT_APP_BASE_API_URL_V2}/registration/register`,
      {
        username,
        email: username,
      },
    );

    return data;
  };

  forgotPassword = async ({ username }) => {
    return axios.post(
      `${window._sonar_env.REACT_APP_BASE_API_URL_V2}/authentication/forgotpassword`,
      {
        email: username,
      },
    );
  };

  logout = async () => {
    localStorage.removeItem('newUserConfirmation');
    history.push('/login');
    const userTheme = localStorage.getItem('theme');
    localStorage.clear();
    localStorage.setItem('theme', userTheme);
    store.dispatch(authLogoutAction());
  };

  confirmUser = async ({ userId, token }) => {
    return axios.post(
      `${window._sonar_env.REACT_APP_BASE_API_URL_V2}/authentication/confirmRegistration/${userId}?${token}`,
    );
  };

  confirmForgot = async ({ email, password, token }) => {
    return axios.post(
      `${window._sonar_env.REACT_APP_BASE_API_URL_V2}/authentication/confirmforgot?confirmationToken=${token}`,
      {
        email,
        password,
      },
    );
  };

  getUser = async (id) => {
    return axios.get(`${window._sonar_env.REACT_APP_BASE_API_URL_V2}/api/users/${id}`);
  };

  getMe = async () => {
    return Api.get(`/me`);
  };
}

export default new AuthService();
export { AuthService };
