import './BeaconReadingsPager.scss';
import '../../scss/variables.scss';
import { capitalize } from '@utils';
import { cloneDeep, get } from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Card, Carousel, CarouselItem } from 'react-bootstrap';
import { ABILITY_MAP, IGNORED_ABILITIES } from '../../constants';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';

function BeaconReadingsPager({ type, name, id, beacons, mapName, placeName, feature }) {
  const [beaconPage, setBeaconPage] = useState(1);

  // AbilityId's that currently don't have Icons
  const abilityIdArray = [
    7, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 26, 27, 28, 29, 30, 31, 32, 34,
    40, 41, 42, 76, 77,
  ];

  // // Creating a clone of Beacons
  // const _beacons = beacons?.length !== 0 ? cloneDeep(beacons) : beacons;

  // const beacon = _beacons ? _beacons[beaconPage - 1] : null;

  const renderBeaconReadings = (beacon) => {
    const beaconReadings = beacon.readings.filter(
      (reading) => !IGNORED_ABILITIES.includes(reading.ability),
    );

    if (!beaconReadings.length) {
      return (
        <div className="w-100 d-flex flex-row">
          <Text className="text-center w-100 fs-md">No Readings</Text>
        </div>
      );
    }

    return beaconReadings.map((reading) => {
      let abilityFormatter = ABILITY_MAP[reading.ability] || ABILITY_MAP['Generic'];

      // Set icon depending if in array/ has reading.icon
      const setIcon = () => {
        // Needed this to have Hours use the History Icon
        readingAbility === 'Hours' ? (readingAbility = 'History') : readingAbility;
        // Needed this to have Battery Change use the Battery Icon
        readingAbility === 'BatteryChange' ? (readingAbility = 'Battery') : readingAbility;
        // Needed this to have Battery Change use the Battery Icon
        readingAbility === 'FuelLevel' ? (readingAbility = 'Dashboard') : readingAbility;

        if (abilityIdArray.includes(reading.abilityId)) {
          return <Icon name={'analytics'} variant="dark" size="x-18" />;
        } else if (reading.icon) {
          return <i className={reading.icon + ` fs-x-18 px-1 sonarIcon-primary`}></i>;
        } else {
          return (
            <Icon
              name={readingAbility.toLowerCase()}
              variant="dark"
              size="x-18"
              disabled={false}
              showPointer={false}
            />
          );
        }
      };

      //Removing spaces from reading.ability for Icons
      let readingAbility = reading.ability.replace(/\s/g, '');
      return (
        <div key={reading.ability} className="mt-3 mb-3 d-flex justify-content-center">
          <div className="d-flex align-items-center beacon-ability-content-wrap">
            {setIcon()}
            <span>{reading.ability}</span>
            <span className="ps-1 pe-2">{abilityFormatter.value(reading)}</span>
          </div>
        </div>
      );
    });
  };

  // Multiple Reading Popup On Asset List Page & Map Page
  return (
    <div className="asset-list-beacon-readings-wrapper p-3">
      <Card.Title>Name: &nbsp;{name}</Card.Title>
      <div className="d-flex flex-row flex-wrap justify-content-between w-100 asset-details mt-3">
        <div key="Asset ID" className="d-flex mt-3 justify-content-center">
          <div className="d-flex flex-column align-items-center me-auto">
            <div className="d-flex">
              <i className={'fa fa-passport fs-1x px-1 sonarIcon-primary me-auto'}></i>
              <p>Asset Id</p>
            </div>
            <p>{id || '--'}</p>
          </div>
        </div>
        <div key="Map Name" className="d-flex mt-3 justify-content-center">
          <div className="d-flex flex-column align-items-center me-auto">
            <div className="d-flex">
              <Icon name="map" variant="dark" size="1x" disabled={false} showPointer={false} />
              <p>Map</p>
            </div>
            <p>{mapName || '--'}</p>
          </div>
        </div>
        {(type === 'Asset' || type === 'Stationary Asset' || type === 'AssetListPage') && (
          <div key="Place Name" className="d-flex mt-3 justify-content-center">
            <div className="d-flex flex-column align-items-center me-auto">
              <div className="d-flex">
                <Icon name="place" variant="dark" size="1x" disabled={false} showPointer={false} />{' '}
                <p>Place</p>
              </div>
              <p>{placeName || '--'}</p>
            </div>
          </div>
        )}
      </div>
      {/* Specific for Maps Beta Only */}
      {feature && (
        <>
          {feature.properties.EntityType !== 'Place' && <hr className="solid" />}
          {feature.properties.DeviceId && (
            <div className="status-row d-flex align-items-center">
              <Text className="fw-bold fs-md">Device ID: &nbsp;</Text>
              <Text className="fs-md">{feature.properties.DeviceId}</Text>
            </div>
          )}
          {feature.properties.LastCheckIn && (
            <div className="status-row d-flex align-items-center">
              <Text className="fw-bold fs-md">Last Check-In: &nbsp;</Text>
              <Text className="fs-md">{feature.properties.LastCheckIn}</Text>
            </div>
          )}
          {feature.properties.OnlineStatus && (
            <div className="status-row d-flex align-items-center">
              <Text className="fw-bold fs-md">Status: &nbsp;</Text>
              <Text className="fs-md">{feature.properties.OnlineStatus}</Text>
            </div>
          )}
          {feature.properties.Floor && (
            <div className="status-row d-flex align-items-center">
              <Text className="fw-bold fs-md">Floor: &nbsp;</Text>
              <Text className="fs-md">{feature.properties.Floor}</Text>
            </div>
          )}
        </>
      )}

      {type.includes('Asset') && beacons && (
        <div>
          <hr className="solid" />
          <div>
            <Card.Title className="d-flex justify-content-center align-items-center">
              Associated Beacons
            </Card.Title>
            <Carousel controls={true} interval={null}>
              {beacons.map((beacon) => {
                return (
                  <CarouselItem key={beacon.id}>
                    <Card.Body className="p-3">
                      <h6 className="d-flex justify-content-center align-items-center">
                        {beacon.name}
                      </h6>
                      {renderBeaconReadings(beacon)}
                    </Card.Body>
                  </CarouselItem>
                );
              })}
            </Carousel>
          </div>
        </div>
      )}
    </div>
  );
}

BeaconReadingsPager.propTypes = {
  beacons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      name: PropTypes.string.isRequired,
      deviceId: PropTypes.string.isRequired,
      readings: PropTypes.arrayOf(
        PropTypes.shape({
          mean: PropTypes.number.isRequired,
          value: PropTypes.string.isRequired,
          timestamp: PropTypes.string.isRequired,
          ability: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  name: PropTypes.string,
  id: PropTypes.any,
  mapName: PropTypes.string,
  placeName: PropTypes.string,
  feature: PropTypes.any,
};

export default BeaconReadingsPager;
